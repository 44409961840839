<template>
	<div class="app-content container center-layout mt-2">
		<div class="content-wrapper">
			<div class="content-header row">
			</div>
			<div class="content-body">
				<section class="flexbox-container">
					<div class="col-12 d-flex align-items-center justify-content-center">
						<div class="col-10 col-md-6 col-lg-4 p-0">
							<div class="card-header bg-transparent border-0">
								<h2 class="error-code text-center mb-2">{{ title }}</h2>
								<h3 class="text-uppercase text-justify">{{ description }}</h3>
							</div>
							<div class="card-content">
								<div class="row py-2">
									<div class="col-12">
										<Link class="btn btn-primary btn-block" href="/">
										<font-awesome-icon icon="home" /> Back to Home
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	</div>
</template>

<script>
import Link from '@/src/components/Link.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHome } from '@fortawesome/free-solid-svg-icons'
library.add(faHome)

export default {
	props: {
		is404: {
			type: Boolean,
		},
	},
	meta({ config }) {
		switch (config.theme.name) {
			case 'timesy':
				return {
					title: "Error - " + config.theme.title,
					description: "",
				}
				break
			default:
				return {
					title: "Error - " + config.theme.title,
					description: "",
				}
		}
	},
	components: {
		Link,
		FontAwesomeIcon,
	},
	data() {
		return {
			title: this.is404 ? '404' : '500',
			description: this.is404 ? 'This page could not be found.' : 'Something went wrong.',
		}
	},
	created() {
	},
	methods: {}
}
</script>

<style lang="scss" scoped>

.flexbox-container {
	display: flex;
	padding-top: 4rem;
	padding-bottom: 7rem;
}

.error-code {
  font-size: 10rem;
}


body h2.error-code {
  font-size: 4rem;
  font-weight: normal;
  text-align: center;
  color: #000000;
  text-transform: none;
}

.card-header h3.text-justify,
body .card-header h4 {
  font-size: 1rem;
  font-weight: normal;
  text-align: justify;
  color: #000000;
  text-transform: none;
}

.btn {
  width: 100%;
  margin: 0.5em 0 0 0;
}
</style>
